import React, { FC, ReactElement } from 'react';

import BehaviourAndStrategyBoxes from 'components/BehaviourAndStrategyBoxes';
import CaseStudySlider from 'components/CaseStudySlider';
import ContentBlock from 'components/ContentBlock';
import HeadingAndText from 'components/HeadingAndText';
import InfographicImage from 'components/InfographicImage';
import PeopleList from 'components/PeopleList';
import SingleCta from 'components/SingleCta';

import { PeopleLandingBodyRendererProps } from './models';

const Components = {
  'Heading And Text': HeadingAndText,
  'Content Block': ContentBlock,
  'Infographic Image': InfographicImage,
  'Single CTA': SingleCta,
  'Behaviour And Strategy Boxes': BehaviourAndStrategyBoxes,
  'People List': PeopleList,
  'Case Study Slider': CaseStudySlider,
};

const PeopleLandingBodyRenderer: FC<PeopleLandingBodyRendererProps> = ({
  modules,
  peopleList,
}): ReactElement => (
  <>
    {modules?.map((block, index) => {
      if (typeof Components[block.structure] === 'undefined') {
        return null;
      }

      const Component = Components[block.structure];

      if (block.structure === 'People List') {
        return (
          <Component
            key={`${block.structure}${index}`}
            module={block.module}
            peopleList={peopleList}
          />
        );
      }

      return <Component key={`${block.structure}${index}`} module={block.module} />;
    })}
  </>
);

export default PeopleLandingBodyRenderer;
