import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import BannerRenderer from 'common/BannerRenderer';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Breadcrumbs from 'components/Breadcrumbs';

import { PeopleLandingPageTypes } from './models';
import PeopleLandingBodyRenderer from './PeopleLandingBodyRenderer';

import './PeopleLandingPage.scss';

const PeopleLandingPage: FC<PeopleLandingPageTypes.PeopleLandingPageType> = ({
  data: { peopleLandingPage, allPeopleProfilePage },
}) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } =
    peopleLandingPage.seo;
  const { level, title, banner, modules, url, lang } = peopleLandingPage;
  const { edges } = allPeopleProfilePage;

  return (
    <Layout headerTransparent>
      <Seo
        {...{
          seo: peopleLandingPage.seo,
          openGraph: peopleLandingPage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div className="banners">
        <Breadcrumbs level={level} />
        {banner && <BannerRenderer {...banner[0]} />}
      </div>
      <div id="modules">
        <PeopleLandingBodyRenderer
          modules={modules}
          peopleList={edges?.map((edge) => ({ ...edge.node }))}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String, $pageId: Int) {
    peopleLandingPage(url: { eq: $url }) {
      level
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      banner {
        ...FragmentBanners
      }
      modules {
        ...FragmentPeopleLandingModules
      }
    }
    allPeopleProfilePage(filter: { parentId: { eq: $pageId } }) {
      edges {
        node {
          name
          jobTitle
          url
          profileImage {
            ...FragmentPeoplePreviewImage
          }
          profileType {
            name
            tagName
          }
          orderList {
            key
            value
          }
        }
      }
    }
  }
`;

export default PeopleLandingPage;
