import { getImage, IArtDirectedImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { ImagesContent } from '../../../@types/content/images';

export const getArtDirectedPeoplePreviewLandingImages = (
  breakpoints: ImagesContent.PeoplePreviewLandingImageBreakpoint[],
  imageData: ImagesContent.PeoplePreviewLandingImage
): IArtDirectedImage[] =>
  breakpoints
    .filter(({ alias }) => !!getImage(imageData[alias]))
    .map(({ alias, media }) => ({
      media,
      image: getImage(imageData[alias]) as IGatsbyImageData,
    }));
