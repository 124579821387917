import React, { FC } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';

import { ImagesContent } from '../../../@types/content/images';
import { getArtDirectedPeoplePreviewLandingImages } from './utils';

const defaultBreakpoints: ImagesContent.PeoplePreviewLandingImageBreakpoint[] = [
  { alias: 'peopleLandingMobile', media: '(max-width: 767px)' },
  { alias: 'peopleLandingTablet', media: '(max-width: 1199px)' },
];

const PeoplePreviewLandingImage: FC<ImagesContent.PeoplePreviewLandingImageProps> = ({
  imageData,
  alt,
  breakpoints = defaultBreakpoints,
  ...restProps
}) => {
  const isArtDirected = Object.keys(imageData).includes('peopleLandingDesktop');
  const mainImage = isArtDirected
    ? getImage((imageData as ImagesContent.PeoplePreviewLandingImage).peopleLandingDesktop)
    : getImage(imageData as ImagesContent.ImageDataLike);

  const images = withArtDirection(
    mainImage as IGatsbyImageData,
    getArtDirectedPeoplePreviewLandingImages(
      breakpoints,
      imageData as ImagesContent.PeoplePreviewLandingImage
    )
  );

  return <GatsbyImage image={images} alt={alt} {...restProps} />;
};

export const createImageWithBreakpoints =
  (
    breakpoints: ImagesContent.PeoplePreviewLandingImageBreakpoint[]
  ): FC<ImagesContent.PeoplePreviewLandingImageProps> =>
  (props: ImagesContent.PeoplePreviewLandingImageProps) =>
    <PeoplePreviewLandingImage {...props} breakpoints={breakpoints} />;

export default PeoplePreviewLandingImage;
