import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { some } from 'lodash';

import { Col, Container, Row } from 'layout';
import PeoplePreviewLandingImage from 'common/images/PeoplePreviewLandingImage';
import { isBrowser } from 'utils/browser';
import getQueryParamByName from 'utils/getQueryParamByName';

import { ModulesContent } from '../../@types/content/modules';
import Arrow from '../../common/icons/arrow-right.svg';
import DropdownArrow from '../../common/icons/dropdownArrow.svg';
import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { PeopleListProps } from './models';

import './PeopleList.scss';

const PeopleList: FC<PeopleListProps> = ({ module, peopleList }) => {
  const peopleQueryParam = 'peopleFilter';

  const filterBtnRef = useRef<HTMLButtonElement>(null);
  const [people, setPeople] = useState<ModulesContent.PeopleListItemType[]>([]);
  const [activeSort, setActiveSort] = useState(module.cmsOrderSortText);
  const [activeFilter, setActiveFilter] = useState(module.profileTypesFilters[0].name);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isMobile, isSmallMobile } = useContext(ScreenRecognitionContext);
  const { pathname } = useLocation();

  const sortPeopleByDefault = (
    currentPeopleList: ModulesContent.PeopleListItemType[],
    currentTabName: string
  ): ModulesContent.PeopleListItemType[] =>
    currentPeopleList.sort((a, b) =>
      some(a.orderList, { key: currentTabName }) && some(b.orderList, { key: currentTabName })
        ? Number(a.orderList.find((pair) => pair.key === currentTabName)?.value) -
          Number(b.orderList.find((pair) => pair.key === currentTabName)?.value)
        : 0
    );

  const sortPeopleAlphabetically = (
    currentPeopleList: ModulesContent.PeopleListItemType[]
  ): ModulesContent.PeopleListItemType[] =>
    currentPeopleList.sort((a, b) => a.name.localeCompare(b.name));

  const sortPeople = (
    currentPeopleList: ModulesContent.PeopleListItemType[],
    sortName: string,
    filterName: string
  ) =>
    sortName === module.alphabeticalSortText
      ? setPeople(sortPeopleAlphabetically(currentPeopleList))
      : setPeople(sortPeopleByDefault(currentPeopleList, filterName));

  useEffect(() => {
    if (!peopleList) return;

    const queryParamFilter = isBrowser() && getQueryParamByName(peopleQueryParam);

    if (queryParamFilter) {
      setActiveFilter(queryParamFilter);
      filterBtnRef.current?.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (!peopleList) return;

    const filtered = peopleList.filter((profile) =>
      profile.profileType.find((profilType) => profilType.name === activeFilter)
    );

    const sorted = sortPeopleByDefault(filtered, activeFilter);

    setPeople(sorted);
  }, [activeFilter]);

  const onChangePeopleFilter = (peopleFilter: string) => {
    window.history.replaceState({}, '', `${pathname}?${peopleQueryParam}=${peopleFilter}`);
  };

  const handleChangeFilter = (filterName) => {
    const filtered = peopleList.filter((profile) =>
      profile.profileType.find((profilType) => profilType.name === filterName)
    );

    setActiveFilter(filterName);

    sortPeople(filtered, activeSort, filterName);
    onChangePeopleFilter(filterName);
  };

  const handleChangeSort = (sortName) => {
    setActiveSort(sortName);

    sortPeople([...people], sortName, activeFilter);
  };

  const onChangeState = () => {
    setIsOpen((prev) => !prev);
  };

  const peopleElement = useMemo(
    () =>
      people?.map((profile) => (
        <div className="people-list__card" key={profile.name}>
          <PeoplePreviewLandingImage
            className="people-list__profile-image"
            imageData={profile.profileImage}
            alt={profile.name}
          />
          <span className="people-list__name h6">{profile.name}</span>
          <span className="people-list__job caption">{profile.jobTitle}</span>
          <Link
            className="people-list__cta link link--pink"
            to={profile.url}
            aria-label={`${profile.name} ${module.viewProfileButtonText}`}
          >
            {module.viewProfileButtonText}
            <Arrow aria-hidden="true" focusable="false" />
          </Link>
        </div>
      )),
    [people]
  );

  const filterButtons = useMemo(
    () =>
      module.profileTypesFilters?.map((filter) => (
        <li className="people-list__tab" key={filter.name}>
          <button
            className={classNames('people-list__filter-btn', {
              active: filter.name === activeFilter,
            })}
            type="button"
            onClick={() => handleChangeFilter(filter.name)}
            ref={filterBtnRef}
            aria-controls="people-list"
          >
            {filter.tagName}
          </button>
        </li>
      )),
    [module.profileTypesFilters, activeFilter, activeSort]
  );

  return (
    <section className="people-list section">
      <Container>
        <Row>
          <Col className="col--no-gutters">
            <div className="people-list__filter">
              {module.profileTypesFilters.length > 2 && (isMobile || isSmallMobile) ? (
                <div className="people-list__filter-container">
                  <ul className="people-list__filter-overflow">{filterButtons}</ul>
                </div>
              ) : (
                <ul className="people-list__tabs">{filterButtons}</ul>
              )}
              <div className="people-list__filter-sort">
                <span className="people-list__filter-label">{module.sortByText}</span>
                <div
                  className={classNames('people-list__sort', {
                    'people-list__sort--active': isOpen,
                  })}
                >
                  <button
                    className="people-list__sort-btn"
                    type="button"
                    aria-expanded={isOpen}
                    onClick={() => {
                      onChangeState();
                    }}
                    aria-label={`${module.sortByText} ${activeSort}`}
                  >
                    {activeSort}
                    <DropdownArrow className="people-list__sort-icon" aria-hidden="true" />
                  </button>
                  <ul
                    className={classNames('people-list__sort-list', {
                      'people-list__sort-list--active': isOpen,
                    })}
                  >
                    <li>
                      <button
                        className={classNames('people-list__sort-item', {
                          'people-list__sort-item--active': activeSort === module.cmsOrderSortText,
                        })}
                        type="button"
                        onClick={() => {
                          handleChangeSort(module.cmsOrderSortText);
                          onChangeState();
                        }}
                      >
                        {module.cmsOrderSortText}
                      </button>
                    </li>
                    <li>
                      <button
                        className={classNames('people-list__sort-item', {
                          'people-list__sort-item--active':
                            activeSort === module.alphabeticalSortText,
                        })}
                        type="button"
                        onClick={() => {
                          handleChangeSort(module.alphabeticalSortText);
                          onChangeState();
                        }}
                      >
                        {module.alphabeticalSortText}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col className="col--no-gutters">
            <div className="people-list__container" id="people-list">
              {peopleElement}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PeopleList;
